/* eslint-disable react/jsx-no-useless-fragment */
import { ReactElement, useContext } from 'react'

import { AuthContext } from 'contexts/AuthContext'

import { checkGroup } from './checkGroup'
import { checkRole } from './checkRole'

type IHasRoleProps = {
  roles?: string[]
  groups?: string[]
  children: ReactElement
}

export function HasRole({ roles, groups, children }: IHasRoleProps) {
  const { user: profile } = useContext(AuthContext)

  return checkRole({
    roles,
    profileRoles: profile?.roles
  }) ||
    checkGroup({
      groups,
      profileRoles: profile?.roles
    }) ? (
    children
  ) : (
    <></>
  )
}
