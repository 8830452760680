/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-underscore-dangle */
import 'styles/global.scss'
import 'dayjs/locale/en'
import 'dayjs/locale/pt-br'
import 'react-toastify/dist/ReactToastify.min.css'
import '@fortawesome/fontawesome-svg-core/styles.css'
import 'nprogress/nprogress.css'

import { config, library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import { AppProps } from 'next/app'
import Head from 'next/head'
import Router, { useRouter } from 'next/router'
import Script from 'next/script'
import NProgress from 'nprogress'
import React, { useEffect } from 'react'
import { ToastContainer } from 'react-toastify'

import Contexts from 'contexts'

import { Footer } from 'components/Footer'
import { Menu } from 'components/Menu'

import * as gtag from 'helpers/gtag'

NProgress.configure({})
Router.events.on('routeChangeStart', () => NProgress.start())
Router.events.on('routeChangeComplete', () => NProgress.done())
Router.events.on('routeChangeError', () => NProgress.done())

library.add(fab)
library.add(fas)

config.autoAddCss = false
library.add(fab, fas)

dayjs.extend(localizedFormat)

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter()

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      gtag.pageview(url)
    }
    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  return (
    <Contexts>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
      </Head>
      <div className="content">
        <Menu />
        <Component {...pageProps} />
        <Footer />
        <ToastContainer theme="dark" />

        <Script
          strategy="afterInteractive"
          src={`https://www.googletagmanager.com/gtag/js?id=${gtag.GA_TRACKING_ID}`}
        />
        <Script
          id="gtag-init"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', '${gtag.GADS_TRACKING_ID}');
            gtag('config', '${gtag.GA_TRACKING_ID}', {
              page_path: window.location.pathname,
            });
            `
          }}
        />
      </div>
    </Contexts>
  )
}

export default MyApp
