import { GetServerSidePropsContext, PreviewData } from 'next'
import { destroyCookie, parseCookies, setCookie } from 'nookies'
import { ParsedUrlQuery } from 'querystring'

type Context =
  | GetServerSidePropsContext<ParsedUrlQuery, PreviewData>
  | undefined

interface ISetAuthCookie {
  ctx?: Context
  accessToken: string
  refreshToken: string
}

export const keys = {
  access: 'TopServers_accessToken',
  refresh: 'TopServers_refreshToken'
}

export const getAuthCookie = (ctx?: Context) => {
  const cookies = parseCookies(ctx)

  return {
    access: cookies[keys.access],
    refresh: cookies[keys.refresh]
  }
}

export const setAuthCookie = ({
  ctx = undefined,
  accessToken,
  refreshToken
}: ISetAuthCookie): void => {
  setCookie(ctx, keys.access, accessToken, {
    maxAge: 60 * 60 * 25 * 30, // 7 days
    path: '/',
    sameSite: true,
    secure: process.env.NODE_ENV === 'production'
  })

  setCookie(ctx, keys.refresh, refreshToken, {
    maxAge: 60 * 60 * 25 * 30, // 30 days
    path: '/',
    sameSite: true,
    secure: process.env.NODE_ENV === 'production'
  })
}

export const removeAuthCookie = (ctx: Context = undefined) => {
  destroyCookie(ctx, keys.access, {
    path: '/'
  })
  destroyCookie(ctx, keys.refresh, {
    path: '/'
  })
  sessionStorage.removeItem('TopServers_resendMail')
}
