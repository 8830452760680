import { Role } from 'contexts/AuthContext'

type CheckGroupProps = {
  profileRoles?: Role[]
  groups?: string[]
}

export function checkGroup({ groups, profileRoles }: CheckGroupProps) {
  if (!profileRoles || !groups) {
    return false
  }
  const groupList = profileRoles.map(r => r.group)
  let hasPermission = false
  groups.forEach(permission => {
    if (groupList.includes(permission)) hasPermission = true
  })

  return hasPermission
}
