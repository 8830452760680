import { ReactNode } from 'react'
import { I18nextProvider } from 'react-i18next'

import i18nCfg from 'services/translate'

import { AppProvider } from './AppContext'
import { AuthProvider } from './AuthContext'

interface IContextsProps {
  children: ReactNode
}

export default function Contexts({ children }: IContextsProps) {
  return (
    <I18nextProvider i18n={i18nCfg}>
      <AuthProvider>
        <AppProvider>{children}</AppProvider>
      </AuthProvider>
    </I18nextProvider>
  )
}
