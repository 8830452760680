/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import Image from 'next/image'
import Link from 'next/link'
import Router from 'next/router'
import { memo, useCallback, useContext, useMemo, useRef, useState } from 'react'
import { ChevronDown, Menu as MenuIcon } from 'react-feather'

import { AuthContext } from 'contexts/AuthContext'

import { Button } from 'components/Button'

import { HasRole } from 'helpers/auth/HasRole'
import { useOnClickOutside } from 'helpers/useOnClickOutside'

import { ActiveLink } from './ActiveLink'
import styles from './Menu.module.scss'
import { MenuTexts } from './Menu.texts'

export const Menu = memo(() => {
  const texts = MenuTexts()

  const { user: profile, isAuthenticated, signOut } = useContext(AuthContext)
  const userMenuRef = useRef<HTMLDivElement>(null)
  const navRef = useRef<HTMLElement>(null)
  const [menuIsOpen, setMenuIsOpen] = useState(false)
  const [showUserMenu, setShowUserMenu] = useState(false)

  useOnClickOutside(userMenuRef, () => setShowUserMenu(false))
  useOnClickOutside(navRef, () => setMenuIsOpen(false))

  Router.events.on('routeChangeStart', () => setMenuIsOpen(false))

  const userName = useMemo(() => {
    const names = profile?.name.split(' ')
    if (!names) return ''

    if (names.length <= 1) {
      return names[0]
    }

    return `${names[0]} ${names.slice(-1)}`
  }, [profile?.name])

  const logout = useCallback(async () => {
    await signOut()
    setShowUserMenu(false)
    setMenuIsOpen(false)
  }, [signOut])

  return (
    <nav className={styles.navbar} ref={navRef}>
      <div className="container">
        <Link href="/">
          <a className={styles.logo}>
            <Image
              src="/assets/logo/bg-dark.svg"
              layout="fill"
              alt="TS"
              title="TopServers"
              priority
            />
          </a>
        </Link>
        <div
          className={`${styles.action} ${menuIsOpen ? styles.menuOpen : ''}`}
        >
          <div className={styles.links}>
            <ActiveLink activeClassName={styles.activeLink} exact href="/">
              <a>{texts.home}</a>
            </ActiveLink>
            <ActiveLink activeClassName={styles.activeLink} href="/servers">
              <a>{texts.topServers}</a>
            </ActiveLink>
            <ActiveLink activeClassName={styles.activeLink} href="/games">
              <a>{texts.games}</a>
            </ActiveLink>
            <ActiveLink activeClassName={styles.activeLink} href="/contact-us">
              <a>{texts.contact}</a>
            </ActiveLink>
            <HasRole groups={['translations']}>
              <ActiveLink
                activeClassName={styles.activeLink}
                href="/admin/translations"
              >
                <a>{texts.translations}</a>
              </ActiveLink>
            </HasRole>
          </div>
          {isAuthenticated && profile ? (
            <div className={styles.userMenu} ref={userMenuRef}>
              <button type="button" onClick={() => setShowUserMenu(s => !s)}>
                <div className={styles.user}>
                  <strong>{userName}</strong>
                  <span>{texts.user.serverCount(profile.servers || 0)}</span>
                </div>
                <div className={styles.caret}>
                  <ChevronDown size={18} />
                </div>
              </button>
              {showUserMenu && (
                <ul className={styles.menuOptions}>
                  <li>
                    <Link href="/account/servers">
                      <a onClick={() => setShowUserMenu(false)}>
                        {texts.user.myServers}
                      </a>
                    </Link>
                  </li>
                  <li>
                    <Link href="/account/me">
                      <a onClick={() => setShowUserMenu(false)}>
                        {texts.user.myProfile}
                      </a>
                    </Link>
                  </li>
                  <li>
                    <Link href="#">
                      <a onClick={logout}>{texts.user.logout}</a>
                    </Link>
                  </li>
                </ul>
              )}
            </div>
          ) : (
            <Button as="link" href="/account/me" size="sm">
              {texts.publishServer}
            </Button>
          )}
        </div>
        <div className={styles.hamburgerMenu}>
          <Button
            outline
            active={menuIsOpen}
            onClick={() => setMenuIsOpen(open => !open)}
          >
            <MenuIcon />
          </Button>
        </div>
      </div>
    </nav>
  )
})
