/* eslint-disable @typescript-eslint/no-explicit-any */
import Router, { useRouter } from 'next/router'
import {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import {
  getAuthCookie,
  removeAuthCookie,
  setAuthCookie
} from 'helpers/auth/cookies'

// eslint-disable-next-line import/no-cycle
import { api } from 'services/api/client'

export type Role = {
  group: string
  roles: string[]
}

export type User = {
  accountId: string
  name: string
  email: string
  country: string
  servers: number
  validateUntil?: Date
  roles: Role[]
}

type SignInCredentials = {
  email: string
  password: string
  captcha: string
  resetCaptcha: () => void
}

type UpdateProfileDTO = {
  name?: string
  country?: string
  captcha: string
  resetCaptcha: () => void
}

type AuthContextData = {
  updateProfile(data: UpdateProfileDTO): Promise<void>
  increaseServers(): void
  signIn(credentials: SignInCredentials): Promise<void>
  signOut(): Promise<void>
  user: User | null
  isAuthenticated: boolean
}

type AuthProviderProps = {
  children: ReactNode
}

export const AuthContext = createContext({} as AuthContextData)

let authChannel: BroadcastChannel

export async function signOut() {
  removeAuthCookie()
  authChannel.postMessage('signOut')
  Router.reload()
}

export function AuthProvider({ children }: AuthProviderProps) {
  const { t } = useTranslation()
  const router = useRouter()
  const [user, setUser] = useState<User | null>(null)

  const isAuthenticated = useMemo(() => !!user, [user])

  const logout = useCallback(async () => {
    signOut()
  }, [])

  useEffect(() => {
    const { access } = getAuthCookie()

    if (access) {
      api
        .get(`/accounts/me`)
        .then(response => setUser(response.data))
        .catch(() => logout())
    } else {
      setUser(null)
    }
  }, [logout])

  useEffect(() => {
    authChannel = new BroadcastChannel('auth')

    authChannel.onmessage = message => {
      switch (message.data) {
        case 'signOut': {
          logout()
          break
        }
        case 'signIn':
          Router.reload()
          break
        default:
          break
      }
    }
  }, []) // eslint-disable-line

  const signIn = useCallback(
    async ({ email, password, captcha }: SignInCredentials) => {
      const errors = {
        'accounts.user.incorrectauth': t('pLogin.errors.incorrectAuth'),
        'captcha.invalid': t('pLogin.errors.invalidCaptcha'),
        general: t('pLogin.errors.general')
      }

      try {
        const response = await api.post('/accounts/sessions', {
          email,
          password,
          captcha
        })

        const { token, refresh_token } = response.data

        setAuthCookie({
          accessToken: token,
          refreshToken: refresh_token
        })
        setUser(response.data.user)
        toast.success(t('pLogin.loggedIn'))

        api.defaults.headers.common.Authorization = `Bearer ${token}`

        router.push('/account/servers')

        authChannel.postMessage('signIn')
      } catch (error: any) {
        const code: string = error.response?.data?.code || error?.code
        toast.error((errors as any)[code || 'general'])
      }
    },
    [router, t]
  )

  const updateProfile = useCallback(
    async (data: UpdateProfileDTO) => {
      if (!isAuthenticated && user) {
        return
      }

      try {
        const postData = { captcha: data.captcha }
        if (data.name) {
          Object.assign(postData, { name: data.name })
        }
        if (data.country) {
          Object.assign(postData, { country: data.country })
        }
        await api.put(`/accounts/profile`, postData)
        toast('Profile updated successfully')
      } catch (error) {
        toast.error('Failed to update profile')
      } finally {
        data.resetCaptcha()
      }
    },
    [isAuthenticated, user]
  )

  const increaseServers = useCallback(() => {
    if (user) {
      setUser({ ...user, servers: (user.servers += 1) })
    }
  }, [user])

  const ContextValue = useMemo(
    () => ({
      signIn,
      signOut: logout,
      user,
      isAuthenticated,
      updateProfile,
      increaseServers
    }),
    [increaseServers, isAuthenticated, signIn, updateProfile, user] // eslint-disable-line
  )

  return (
    <AuthContext.Provider value={ContextValue}>{children}</AuthContext.Provider>
  )
}
