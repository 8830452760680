/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEnhancedEffect } from './useEnhancedEffect'

export const useOnClickOutside = (ref: any, handler: any): void => {
  useEnhancedEffect(() => {
    const listener = (event: any): unknown => {
      if (!ref.current || ref.current.contains(event.target)) return

      handler(event)
    }

    document.addEventListener('mousedown', listener)
    document.addEventListener('touchstart', listener)

    return () => {
      document.removeEventListener('mousedown', listener)
      document.removeEventListener('touchstart', listener)
    }
  }, [ref, handler])
}
