/* eslint-disable no-param-reassign */
import axios, { AxiosError } from 'axios'
import { GetServerSidePropsContext } from 'next'

import { signOut } from 'contexts/AuthContext'

import { setAuthCookie, getAuthCookie } from 'helpers/auth/cookies'

import { AuthTokenError } from './errors/AuthTokenError'

let isRefreshing = false
let failedRequestQueue: {
  onSuccess: (token: string) => void
  onFailure: (err: AxiosError) => void
}[] = []

export function setupAPIClient(
  ctx: GetServerSidePropsContext | undefined = undefined
) {
  let cookies = getAuthCookie(ctx)
  const appUrl = process.env.NEXT_PUBLIC_URL

  const api = axios.create({
    baseURL: `${appUrl}/api`,
    headers: cookies.access ? { Authorization: `Bearer ${cookies.access}` } : {}
  })

  api.interceptors.response.use(
    response => response,
    (error: AxiosError) => {
      if (error.response?.status === 401) {
        if (error.response.data?.code === 'accounts.token.invalid') {
          cookies = getAuthCookie(ctx)

          const { refresh } = cookies
          const originalConfig = error.config

          if (!isRefreshing) {
            isRefreshing = true

            api
              .post(
                '/accounts/refresh-token',
                {},
                { headers: { 'x-access-token': refresh } }
              )
              .then(response => {
                const { token: accessToken, refresh_token } = response.data

                setAuthCookie({
                  ctx,
                  accessToken,
                  refreshToken: refresh_token
                })

                api.defaults.headers.common.Authorization = `Bearer ${accessToken}`

                failedRequestQueue.forEach(request =>
                  request.onSuccess(accessToken)
                )
                failedRequestQueue = []
              })
              .catch(err => {
                failedRequestQueue.forEach(request => request.onFailure(err))
                failedRequestQueue = []

                if (process.browser) {
                  signOut()
                }
              })
              .finally(() => {
                isRefreshing = false
              })
          }

          return new Promise((resolve, reject) => {
            failedRequestQueue.push({
              onSuccess: (token: string) => {
                if (originalConfig.headers) {
                  originalConfig.headers.Authorization = `Bearer ${token}`
                } else {
                  originalConfig.headers = { Authorization: `Bearer ${token}` }
                }

                resolve(api(originalConfig))
              },
              onFailure: (err: AxiosError) => {
                reject(err)
              }
            })
          })
        }
        if (process.browser) {
          signOut()
        } else {
          const data = error.response?.data
          return Promise.reject(new AuthTokenError(data?.code, data?.message))
        }
      }

      return Promise.reject(error)
    }
  )

  return api
}
