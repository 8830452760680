import { useTranslation } from 'react-i18next'

export function MenuTexts() {
  const { t } = useTranslation()

  return {
    home: t('menu.home'),
    topServers: t('menu.topServers'),
    games: t('menu.games'),
    contact: t('menu.contact'),
    translations: t('menu.translations'),
    publishServer: t('menu.publishServer'),
    user: {
      serverCount: (count: number) => t('menu.user.serverCount', { count }),
      myServers: t('menu.user.myServers'),
      myProfile: t('menu.user.myProfile'),
      logout: t('menu.user.logout')
    }
  }
}
