import { useTranslation } from 'react-i18next'

export function FooterTexts() {
  const { t } = useTranslation()

  return {
    contactUs: t('footer.contactUs'),
    privacyPolicy: t('footer.privacyPolicy'),
    myAccount: t('footer.myAccount'),
    faq: t('footer.faq')
  }
}
