import dayjs from 'dayjs'
import i18n from 'i18next'
import ChainedBackend from 'i18next-chained-backend'
import HttpBackend from 'i18next-http-backend'
import LocalStorageBackend from 'i18next-localstorage-backend'
import resourcesToBackend from 'i18next-resources-to-backend'
import { parseCookies } from 'nookies'
import { initReactI18next } from 'react-i18next'

const apiUrl = process.env.NEXT_PUBLIC_BACKEND_URL
const cookies = parseCookies()
const savedLang = cookies['user-lang']

export const supportedLngs = ['en', 'pt-br']

dayjs.locale(savedLang || 'en')

if (!i18n.isInitialized) {
  // const SEVENDAYS_IN_MS = 7 * 24 * 60 * 60 * 1000
  // const ONEMINUTE_IN_MS = 60 * 1000
  i18n
    .use(initReactI18next)
    .use(ChainedBackend)
    .init(
      {
        supportedLngs,
        fallbackLng: 'en',
        preload: ['en'],
        lng: savedLang || 'en',
        lowerCaseLng: true,
        debug: false,
        detection: {
          order: ['path'],
          lookupFromPathIndex: 0,
          checkWhitelist: true
        },
        interpolation: {
          escapeValue: false,
          formatSeparator: '.'
        },
        react: { useSuspense: false },
        parseMissingKeyHandler(key) {
          return process.env.NODE_ENV === 'production'
            ? 'Loading text'
            : `!! ${key} !!`
        },
        backend: {
          backends: [
            LocalStorageBackend,
            HttpBackend,
            resourcesToBackend((language, _namespace, callback) => {
              import(`assets/locales/${language}.json`)
                .then(({ default: resources }) => {
                  callback(null, resources)
                })
                .catch(error => {
                  callback(error, null)
                })
            })
          ],
          backendOptions: [
            { prefix: 'TopServers_intl_' },
            { loadPath: `${apiUrl}/translations/{{lng}}?i18n=true` },
            {}
          ]
        }
      },
      () => {
        i18n.reloadResources()
      }
    )
}

export default i18n
