/* eslint-disable @typescript-eslint/no-explicit-any */
import dayjs from 'dayjs'
import { parseCookies, setCookie } from 'nookies'
import { createContext, ReactNode, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useEnhancedEffect } from 'helpers/useEnhancedEffect'

import { api } from 'services/api/client'
import { IStatisticsResponse } from 'services/api/models/Translation'
import { supportedLngs } from 'services/translate'

export type Languages = 'en' | 'pt-br' | string

type AppContextData = {
  language: Languages
  statistics: IStatisticsResponse[]
  changeLanguage(lng: Languages): void
  refreshLanguage(): Promise<void>
  getStatistics(): Promise<void>
}

type AuthProviderProps = {
  children: ReactNode
}

export const AppContext = createContext({} as AppContextData)

export function AppProvider({ children }: AuthProviderProps) {
  const cookies = parseCookies()
  const savedLang = cookies['user-lang']

  const { i18n } = useTranslation()
  const [language, setLanguage] = useState(savedLang || 'en')
  const [statistics, setStatistics] = useState<IStatisticsResponse[]>([])

  const changeLanguage = useCallback(
    (lng: Languages | string) => {
      if (supportedLngs.includes(lng)) {
        i18n.changeLanguage(lng)
        setCookie(undefined, 'user-lang', lng, {
          maxAge: 60 * 60 * 25 * 30, // 30 days
          path: '/',
          sameSite: true,
          secure: process.env.NODE_ENV === 'production'
        })
        dayjs.locale(lng)
        setLanguage(lng)
      }
    },
    [i18n]
  )

  const getStatistics = useCallback(async () => {
    const request = await api.get<IStatisticsResponse[]>(
      '/translations/statistics'
    )
    setStatistics(request.data)
  }, [])

  useEnhancedEffect(() => {
    changeLanguage(language)
    getStatistics()
  }, []) // eslint-disable-line

  const refreshLanguage = useCallback(async () => {
    const languages = ['en', 'pt-br']
    languages.forEach(lng =>
      localStorage.removeItem(`TopServers_intl_${lng}-translation`)
    )
    await i18n.reloadResources()
    await getStatistics()
  }, [getStatistics, i18n])

  const ContextValue = useMemo(
    () => ({
      language,
      statistics,
      changeLanguage,
      refreshLanguage,
      getStatistics
    }),
    [changeLanguage, getStatistics, language, refreshLanguage, statistics]
  )

  return (
    <AppContext.Provider value={ContextValue}>{children}</AppContext.Provider>
  )
}
