import Link from 'next/link'
import { useRouter } from 'next/router'
import { cloneElement, useMemo } from 'react'

import { IActiveLinkProps } from './ActiveLink.types'

export function ActiveLink({
  children,
  activeClassName,
  exact = false,
  ...rest
}: IActiveLinkProps) {
  const { asPath } = useRouter()

  const className = useMemo(() => {
    if (exact) return asPath === rest.href ? activeClassName : ''
    return asPath.startsWith(String(rest.href)) ? activeClassName : ''
  }, [activeClassName, asPath, exact, rest.href])

  return (
    <Link {...rest}>
      {cloneElement(children, {
        className
      })}
    </Link>
  )
}
