import { Role } from 'contexts/AuthContext'

import { stringifyRoles } from 'helpers/stringifyRoles'

type CheckRoleProps = {
  profileRoles?: Role[]
  roles?: string[]
}

export function checkRole({ roles, profileRoles }: CheckRoleProps) {
  if (!profileRoles || !roles) {
    return false
  }
  const roleList = stringifyRoles(profileRoles)
  let hasPermission = false
  roles.forEach(permission => {
    if (roleList.includes(permission)) hasPermission = true
  })

  return hasPermission
}
