export const GA_TRACKING_ID = 'G-57X8ZKNRD6'
export const GADS_TRACKING_ID = 'AW-967304372'

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url: string) => {
  const { gtag } = window as any
  if (gtag) {
    gtag('config', GA_TRACKING_ID, {
      page_path: url
    })
  }
}

interface IGtagEvent {
  action: string
  category?: string
  label?: string
  send_to?: string
  value?: string | number
}
// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = ({
  action,
  category,
  label,
  value,
  ...rest
}: IGtagEvent) => {
  const { gtag } = window as any
  if (gtag) {
    gtag('event', action, {
      event_category: category,
      event_label: label,
      value,
      nonInteraction: true, // avoids affecting bounce rate.
      ...rest
    })
  }
}
