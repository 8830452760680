import Link from 'next/link'

import styles from './Button.module.scss'
import { IButtonProps } from './Button.types'

export function Button({
  children,
  variant = 'primary',
  outline = false,
  active = false,
  size = 'md',
  as: type = 'button',
  className = '',
  disabled = false,
  ...rest
}: IButtonProps) {
  const classNames = `
    ${styles.button}
    ${styles[`size-${size}`]}
    ${styles[`variant-${variant}`]}
    ${outline ? styles.outline : ''}
    ${active ? styles.active : ''}
    ${disabled ? styles.disabled : ''}
    ${className}
  `

  if (type === 'link') {
    return (
      <Link href="/" {...(rest as any)}>
        <a className={classNames}>{children}</a>
      </Link>
    )
  }
  if (type === 'button') {
    return (
      <button type="button" {...rest} className={classNames}>
        {children}
      </button>
    )
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <></>
}
