import Link from 'next/link'
import { useRouter } from 'next/router'
import { useCallback, useContext, useMemo, useRef, useState } from 'react'
import { ChevronDown } from 'react-feather'

import { AppContext } from 'contexts/AppContext'

import { HasRole } from 'helpers/auth/HasRole'
import languageCodes from 'helpers/languageCodes.json'
import { useEnhancedEffect } from 'helpers/useEnhancedEffect'
import { useOnClickOutside } from 'helpers/useOnClickOutside'

import i18n, { supportedLngs } from 'services/translate'

import styles from './Footer.module.scss'
import { FooterTexts } from './Footer.texts'

export function Footer() {
  const languageRef = useRef<HTMLDivElement>(null)
  const { language, statistics, changeLanguage } = useContext(AppContext)
  const texts = FooterTexts()
  const [languageOpen, setLanguageOpen] = useState(false)
  const { locale } = useRouter()

  useOnClickOutside(languageRef, () => setLanguageOpen(false))

  const languageName = useMemo(
    () =>
      languageCodes.find(lc => lc.code.toLowerCase() === language)?.language ||
      language,
    [language]
  )

  const languages = useMemo(() => {
    if (!statistics) {
      return []
    }
    const codes = statistics.map(s => s.language)
    return languageCodes
      .filter(lc => codes.includes(lc.code))
      .map(l => ({
        ...l,
        percentage:
          statistics.find(s => s.language === l.code)?.percentageTranslated || 0
      }))
  }, [statistics])

  const handleChangeLanguage = useCallback(
    newLang => {
      if (supportedLngs.includes(newLang)) {
        changeLanguage(newLang)
      }
      setLanguageOpen(false)
    },
    [changeLanguage]
  )

  useEnhancedEffect(() => {
    if (!language && i18n.isInitialized) {
      const localeLower = locale?.toLowerCase()
      const lang = languages.find(
        l => l.code.toLowerCase() === localeLower
      )?.code
      handleChangeLanguage(lang || localeLower)
    }
  }, [handleChangeLanguage, language, languages, locale])

  const shouldShowLanguage = useMemo(
    () => languageOpen && languages.length > 0,
    [languages, languageOpen]
  )

  return (
    <div className={`${styles.footer} container`}>
      <div className={styles.links}>
        <Link href="/contact-us">{texts.contactUs}</Link>
        {/* <Link href="/privacy-policy">{texts.privacyPolicy}</Link> */}
        <Link href="/account/me">{texts.myAccount}</Link>
        <Link href="/faq">{texts.faq}</Link>
        <div ref={languageRef} className={styles.language}>
          <button type="button" onClick={() => setLanguageOpen(true)}>
            {languageName}
            {languages.length > 0 && <ChevronDown size={16} />}
          </button>
          {shouldShowLanguage && (
            <ul>
              {languages.map(l => (
                <li
                  key={l.code}
                  className={l.code === language ? styles.active : ''}
                >
                  <button
                    type="button"
                    onClick={() => handleChangeLanguage(l.code)}
                  >
                    {l.language}{' '}
                    <HasRole groups={['translations']}>
                      <small>[{(l.percentage * 100).toFixed(1)}%]</small>
                    </HasRole>
                  </button>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>

      <p>&copy; Top Servers - 2022</p>
    </div>
  )
}
